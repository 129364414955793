import '@styles/main.scss';
import '@styles/common/tailwind.css';

import ActivitiesSwiper from './components/activities-swiper';
import Menu from './components/menu';
import Swiper, {Navigation} from 'swiper';
import 'swiper/css';
import EventSwiper from './components/events-swiper';

class App {
    constructor() {
        new ActivitiesSwiper();
        new Menu();
        new EventSwiper();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
    
});
